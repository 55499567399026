import React from "react";
import { AllImages } from "../assets/image";

export default function Raktamokshana() {
  return (
    <>
      <div
        className="container"
        style={{ backgroundColor: "#778899", color: "white" }}
      >
        <div className="row">
          <h1>Raktamokshana Services in Durg</h1>
        </div>
      </div>
      <div className="container">
        <div className="row mt-4">
          <h1>About Raktamokshana</h1>
        </div>
        <div className="row">
          <p>
            Leech therapy is an ancient medical practice that involves the use
            of medicinal leeches for healing purposes. It is also known as
            Raktmokshana as it is used to remove bad blood from the body which
            causes various problem. This traditional therapy has been used in
            various cultures for centuries and has recently gained popularity in
            the Western world as a complementary or alternative therapy. At
            Patanjali Wellness Centre in Hyderabad, our practitioners are
            trained in the safe and effective use of medicinal leeches,
            providing our clients with a unique and holistic approach to
            wellness.
          </p>
        </div>
        <div className="row">
          <div className="col-md-6">
            <img src={AllImages.Raktmokshan1} width="500px" height="400px" />
            <img
              src={AllImages.Raktmokshan2}
              width="500px"
              height="400px"
              className="mt-2"
            />
          </div>
          <div className="col-md-6">
            <h1>Where Leech Therapy is the Right Solution</h1>
            <p>
              Leech therapy can be used for a variety of conditions, including:
            </p>
            <ul class="list-group">
              <li class="list-group-item">
                Varicose veins: Leech therapy can improve blood circulation and
                reduce inflammation, making it an effective treatment for
                varicose veins.
              </li>
              <li class="list-group-item">
                Increased mental clarity and focus: By cleansing the frontal
                lobe and improving nasal respiration, Shatkarma can help to
                enhance mental clarity and focus.
              </li>
              <li class="list-group-item">
                Psoriasis: Leech therapy can reduce inflammation and promote
                faster healing, making it an effective treatment for psoriasis.
              </li>
              <li class="list-group-item">
                Arthritis: The anti-inflammatory substances released by leeches
                can help to soothe joint pain and reduce symptoms associated
                with arthritis.
              </li>
              <li class="list-group-item">
                Migraines: Leech therapy can improve blood flow and reduce
                muscle tension, making it an effective treatment for migraines.
              </li>
              <li class="list-group-item">
                Skin disorders: Leech therapy can promote faster healing and
                reduce inflammation, making it an effective treatment for
                various skin disorders, including eczema and acne.
              </li>
              <li class="list-group-item">
                Musculoskeletal disorders: The anti-inflammatory and
                pain-relieving properties of leeches can help to reduce symptoms
                associated with musculoskeletal disorders, including back pain
                and sciatica.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
