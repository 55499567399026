import React from "react";
import "../assets/css/service.css";
import { Link } from "react-router-dom";

export default function services() {
  return (
    <div className="innerMenu">
      <div
        className="container"
        style={{ border: "2px solid #ffda6b" }}
        id="submenus"
      >
        <div className="row">
          <div id="submenu">
            <ul className="mt-3">
              <li>AYURVEDA</li>
              <li>NATUROPATHY</li>
              <li>YOGA AND MEDITATION</li>
              {/* <li>CHIROPRACTIC</li> */}
            </ul>
          </div>
        </div>
        <div className="row ms-4 mt-2">
          <div className="col-md-3 border border-warning">
            <Link
              to="#"
              style={{
                textDecoration: "none",
                color: "black",
              }}
            >
              Panchakarma
            </Link>
          </div>
          <div className="col-md-3 ms-2 border border-warning">
            <Link
              to="#"
              style={{
                textDecoration: "none",
                color: "black",
              }}
            >
              DIET THERAPY
            </Link>
          </div>
          <div className="col-md-3 ms-2 border border-warning">
            <Link
              to="#"
              style={{
                textDecoration: "none",
                color: "black",
              }}
            >
              YAJYA THEARPY
            </Link>
          </div>
          <div className="col-md-3"></div>
        </div>
        <div className="row ms-4 mt-2">
          <div className="col-md-3 border border-warning">
            <Link
              to="#"
              style={{
                textDecoration: "none",
                color: "black",
              }}
            >
              Shatkarma
            </Link>
          </div>
          <div className="col-md-3 ms-2 border border-warning">
            <Link
              to="#"
              style={{
                textDecoration: "none",
                color: "black",
              }}
            >
              MUD PACK
            </Link>
          </div>
          <div className="col-md-3"></div>
          <div className="col-md-3"></div>
        </div>
        <div className="row ms-4 mt-2">
          <div className="col-md-3 border border-warning">
            <Link
              to="#"
              style={{
                textDecoration: "none",
                color: "black",
              }}
            >
              Raktamokshana (Leech Therapy)
            </Link>
          </div>
          <div className="col-md-3 ms-2 border border-warning">
            <Link
              to="#"
              style={{
                textDecoration: "none",
                color: "black",
              }}
            >
              HYDRO THERAPY
            </Link>
          </div>
          <div className="col-md-3"></div>
          <div className="col-md-3"></div>
        </div>
        <div className="row ms-4 mt-2">
          <div className="col-md-3 border border-warning">
            <Link
              to="#"
              style={{
                textDecoration: "none",
                color: "black",
              }}
            >
              SHIRODHARA
            </Link>
          </div>
          <div className="col-md-3 ms-2 border border-warning">
            <Link
              to="#"
              style={{
                textDecoration: "none",
                color: "black",
              }}
            >
              COLON HYDRO THERAPY
            </Link>
          </div>
          <div className="col-md-3"></div>
          <div className="col-md-3"></div>
        </div>
        <div className="row ms-4 mt-2">
          <div className="col-md-3 border border-warning">
            <Link
              to="#"
              style={{
                textDecoration: "none",
                color: "black",
              }}
            >
              ABHYANGAM
            </Link>
          </div>
          <div className="col-md-3 ms-2 border border-warning">
            <Link
              to="#"
              style={{
                textDecoration: "none",
                color: "black",
              }}
            >
              ACUPRESSURE
            </Link>
          </div>
          <div className="col-md-3"></div>
          <div className="col-md-3"></div>
        </div>
        <div className="row ms-4 mt-2">
          <div className="col-md-3 border border-warning">
            <Link
              to="#"
              style={{
                textDecoration: "none",
                color: "black",
              }}
            >
              POTLI MASSAGE
            </Link>
          </div>
          <div className="col-md-3 ms-2 border border-warning">
            <Link
              to="#"
              style={{
                textDecoration: "none",
                color: "black",
              }}
            >
              ACUPUNCTURE
            </Link>
          </div>
          <div className="col-md-3"></div>
          <div className="col-md-3"></div>
        </div>
        <div className="row ms-4 mt-2">
          <div className="col-md-3 border border-warning">
            <Link
              to="#"
              style={{
                textDecoration: "none",
                color: "black",
              }}
            >
              FACE REFLEXOLOGY
            </Link>
          </div>
          <div className="col-md-3 ms-2 border border-warning">
            <Link
              to="#"
              style={{
                textDecoration: "none",
                color: "black",
              }}
            >
              SINGHI/CUPPING THERAPY
            </Link>
          </div>
          <div className="col-md-3"></div>
          <div className="col-md-3"></div>
        </div>
        <div className="row ms-4 mb-3 mt-2">
          <div className="col-md-3 border border-warning">
            <Link
              to="#"
              style={{
                textDecoration: "none",
                color: "black",
              }}
            >
              FOOT REFLEXOLOGY
            </Link>
          </div>
          <div className="col-md-3"></div>
          <div className="col-md-3"></div>
          <div className="col-md-3"></div>
        </div>
      </div>
    </div>
  );
}
