import React from "react";
import { AllImages } from "../assets/image";
import "../assets/css/carousel.css";

export default function Carousel() {
  return (
    <div>
      <div className="container" id="carosel">
        <div className="row py-3">
          <div className="col">
            <div
              id="myCarousel"
              className="carousel slide"
              data-bs-ride="carousel"
            >
              <div className="carousel-indicators">
                <button
                  type="button"
                  data-bs-target="#myCarousel"
                  data-bs-slide-to="0"
                  class="active"
                  aria-current="true"
                  aria-label="Slide 1"
                ></button>
                <button
                  type="button"
                  data-bs-target="#myCarousel"
                  data-bs-slide-to="1"
                  aria-label="Slide 2"
                ></button>
                <button
                  type="button"
                  data-bs-target="#myCarousel"
                  data-bs-slide-to="2"
                  aria-label="Slide 3"
                ></button>
              </div>
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    src={AllImages.slider1}
                    className="d-block w-100"
                    alt=""
                  />
                  <div className="carousel-caption d-none d-md-block">
                    {/* <h5 className="text-dark">First slide label</h5> */}
                    {/* <p className="text-dark">
                      Some representative placeholder content for the first
                      slide.
                    </p> */}
                  </div>
                </div>
                <div className="carousel-item">
                  <img
                    src={AllImages.slider2}
                    className="d-block w-100"
                    alt=""
                  />
                  <div className="carousel-caption d-none d-md-block">
                    {/* <h5 className="text-dark">Second slide label</h5> */}
                    {/* <p className="text-dark">
                      Some representative placeholder content for the first
                      slide.
                    </p> */}
                  </div>
                </div>
                <div className="carousel-item">
                  <img
                    src={AllImages.slider4}
                    className="d-block w-100"
                    alt=""
                  />
                  <div className="carousel-caption d-none d-md-block">
                    {/* <h5>Third slide label</h5> */}
                    {/* <p>
                      Some representative placeholder content for the first
                      slide.
                    </p> */}
                  </div>
                </div>
              </div>
              <button
                className="carousel-control-prev"
                type="button"
                data-bs-target="#myCarousel"
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#myCarousel"
                data-bs-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
