import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import MenuIcon from "@mui/icons-material/Menu";
import "../assets/css/SideNavBar.css";
import { Link } from "react-router-dom";
import Services from "./services.js";
import HomeIcon from "@mui/icons-material/Home";
import GroupsIcon from "@mui/icons-material/Groups";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import CollectionsIcon from "@mui/icons-material/Collections";
import MovieIcon from "@mui/icons-material/Movie";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import StarBorder from "@mui/icons-material/StarBorder";

function SideNavBar({ outletRef }) {
  const scrollpage = () => {
    if (outletRef.current) {
      const { current } = outletRef;
      window.scrollTo({
        top: current.offsetTop,
        behavior: "smooth",
      });
    }
  };

  const [open, setOpen] = React.useState(true);

  const handleClick = (e) => {
    e.stopPropagation();
    setOpen(!open);
  };

  const [state, setState] = React.useState({
    left: true,
  });
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const listMenuItems = [
    {
      id: "home",
      title: "Home",
      icon: HomeIcon,
      redirectTo: "/",
      linkClickHandler: () => {},
    },
    {
      id: "aboutus",
      title: "About us",
      icon: GroupsIcon,
      redirectTo: "/aboutus",
      linkClickHandler: scrollpage,
    },
    {
      id: "services",
      title: "Services",
      icon: ManageAccountsIcon,
      redirectTo: null,
      clickHandler: handleClick,
      linkClickHandler: () => {},
    },
    {
      id: "packages",
      title: "Packages",
      icon: Inventory2Icon,
      redirectTo: "/packages",
      linkClickHandler: scrollpage,
    },
    {
      id: "gallery",
      title: "Gallery",
      icon: CollectionsIcon,
      redirectTo: "/gallery",
      linkClickHandler: scrollpage,
    },
    {
      id: "media",
      title: "Media",
      icon: MovieIcon,
      redirectTo: "/media",
      linkClickHandler: scrollpage,
    },
    {
      id: "contactus",
      title: "Contactus",
      icon: ContactMailIcon,
      redirectTo: "/contactus",
      linkClickHandler: scrollpage,
    },
    {
      id: "bookappointment",
      title: "Book Appointment",
      icon: EditCalendarIcon,
      redirectTo: "/bookappointment",
      linkClickHandler: scrollpage,
    },
  ];

  const list = (anchor) => (
    <Box
      style={{ height: "inherit" }}
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List
        className="bg-success overflow-auto text-white"
        style={{ height: "inherit" }}
      >
        {listMenuItems.map((navItem) => {
          return (
            <React.Fragment key={navItem.id}>
              <ListItem disablePadding>
                <ListItemButton onClick={navItem.clickHandler}>
                  <ListItemIcon>
                    <navItem.icon className="text-white" />
                  </ListItemIcon>
                  {navItem.id === "services" ? (
                    <React.Fragment>
                      <ListItemText primary="Services" />
                      {open ? <ExpandLess /> : <ExpandMore />}
                    </React.Fragment>
                  ) : (
                    <Link
                      className="nav-link text-white"
                      to={navItem.redirectTo}
                      onClick={navItem.linkClickHandler}
                    >
                      {navItem.title}
                    </Link>
                  )}
                </ListItemButton>
              </ListItem>
              {navItem.id === "services" && (
                <Collapse in={open} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItemButton sx={{ pl: 4 }}>
                      <ListItemIcon>
                        <StarBorder className="text-white" />
                      </ListItemIcon>
                      <Link
                        className="nav-link text-white"
                        to="/panchakarma"
                        onClick={scrollpage()}
                      >
                        <ListItemText primary="Panchakarma" />
                      </Link>
                    </ListItemButton>
                  </List>
                  <List component="div" disablePadding>
                    <ListItemButton sx={{ pl: 4 }}>
                      <ListItemIcon>
                        <StarBorder className="text-white" />
                      </ListItemIcon>
                      <Link
                        className="nav-link text-white"
                        to="/shatkarma"
                        onClick={scrollpage()}
                      >
                        <ListItemText primary="Shatkarma" />
                      </Link>
                    </ListItemButton>
                  </List>
                  <List component="div" disablePadding>
                    <ListItemButton sx={{ pl: 4 }}>
                      <ListItemIcon>
                        <StarBorder className="text-white" />
                      </ListItemIcon>
                      <Link
                        className="nav-link text-white"
                        to="/raktamokshana"
                        onClick={scrollpage()}
                      >
                        <ListItemText primary="Raktamokshana" />
                      </Link>
                    </ListItemButton>
                  </List>
                  <List component="div" disablePadding>
                    <ListItemButton sx={{ pl: 4 }}>
                      <ListItemIcon>
                        <StarBorder className="text-white" />
                      </ListItemIcon>
                      <Link
                        className="nav-link text-white"
                        to="/shirodhara"
                        onClick={navItem.linkClickHandler}
                      >
                        <ListItemText primary="Shirodhara" />
                      </Link>
                    </ListItemButton>
                  </List>

                  <List component="div" disablePadding>
                    <ListItemButton sx={{ pl: 4 }}>
                      <ListItemIcon>
                        <StarBorder className="text-white" />
                      </ListItemIcon>
                      <Link
                        className="nav-link text-white"
                        to="/abhyangam"
                        onClick={navItem.linkClickHandler}
                      >
                        <ListItemText primary="Abhyangam" />
                      </Link>
                    </ListItemButton>
                  </List>
                  <List component="div" disablePadding>
                    <ListItemButton sx={{ pl: 4 }}>
                      <ListItemIcon>
                        <StarBorder className="text-white" />
                      </ListItemIcon>
                      <Link
                        className="nav-link text-white"
                        to="/potliMassage"
                        onClick={navItem.linkClickHandler}
                      >
                        <ListItemText primary="PotliMassage" />
                      </Link>
                    </ListItemButton>
                  </List>
                  <List component="div" disablePadding>
                    <ListItemButton sx={{ pl: 4 }}>
                      <ListItemIcon>
                        <StarBorder className="text-white" />
                      </ListItemIcon>
                      <Link
                        className="nav-link text-white"
                        to="/dietTherapy"
                        onClick={navItem.linkClickHandler}
                      >
                        <ListItemText primary="DietTherapy" />
                      </Link>
                    </ListItemButton>
                  </List>
                  <List component="div" disablePadding>
                    <ListItemButton sx={{ pl: 4 }}>
                      <ListItemIcon>
                        <StarBorder className="text-white" />
                      </ListItemIcon>
                      <Link
                        className="nav-link text-white"
                        to="/mudPack"
                        onClick={navItem.linkClickHandler}
                      >
                        <ListItemText primary="MudPack" />
                      </Link>
                    </ListItemButton>
                  </List>
                  <List component="div" disablePadding>
                    <ListItemButton sx={{ pl: 4 }}>
                      <ListItemIcon>
                        <StarBorder className="text-white" />
                      </ListItemIcon>
                      <Link
                        className="nav-link text-white"
                        to="/colonHydrotherapy"
                        onClick={navItem.linkClickHandler}
                      >
                        <ListItemText primary="ColonHydrotherapy" />
                      </Link>
                    </ListItemButton>
                  </List>
                  <List component="div" disablePadding>
                    <ListItemButton sx={{ pl: 4 }}>
                      <ListItemIcon>
                        <StarBorder className="text-white" />
                      </ListItemIcon>
                      <Link
                        className="nav-link text-white"
                        to="/acupuncture"
                        onClick={navItem.linkClickHandler}
                      >
                        <ListItemText primary="Acupuncture" />
                      </Link>
                    </ListItemButton>
                  </List>
                  <List component="div" disablePadding>
                    <ListItemButton sx={{ pl: 4 }}>
                      <ListItemIcon>
                        <StarBorder className="text-white" />
                      </ListItemIcon>
                      <Link
                        className="nav-link text-white"
                        to="/cuppingTherapy"
                        onClick={navItem.linkClickHandler}
                      >
                        <ListItemText primary="CuppingTherapy" />
                      </Link>
                    </ListItemButton>
                  </List>
                  <List component="div" disablePadding>
                    <ListItemButton sx={{ pl: 4 }}>
                      <ListItemIcon>
                        <StarBorder className="text-white" />
                      </ListItemIcon>
                      <Link
                        className="nav-link text-white"
                        to="/yoga"
                        onClick={navItem.linkClickHandler}
                      >
                        <ListItemText primary="Yoga" />
                      </Link>
                    </ListItemButton>
                  </List>
                  <List component="div" disablePadding>
                    <ListItemButton sx={{ pl: 4 }}>
                      <ListItemIcon>
                        <StarBorder className="text-white" />
                      </ListItemIcon>
                      <ListItemText primary="YOGA AND MEDITATION" />
                    </ListItemButton>
                  </List>
                  {/* <List component="div" disablePadding>
                    <ListItemButton sx={{ pl: 4 }}>
                      <ListItemIcon>
                        <StarBorder className="text-white" />
                      </ListItemIcon>
                      <ListItemText primary="CHIROPRACTIC" />
                    </ListItemButton>
                  </List> */}
                  <List component="div" disablePadding>
                    <ListItemButton sx={{ pl: 4 }}>
                      <ListItemIcon>
                        <StarBorder className="text-white" />
                      </ListItemIcon>
                      <ListItemText primary="Doctor Consultation" />
                    </ListItemButton>
                  </List>
                  <List component="div" disablePadding>
                    <ListItemButton sx={{ pl: 4 }}>
                      <ListItemIcon>
                        <StarBorder className="text-white" />
                      </ListItemIcon>
                      <ListItemText primary="Female Doctor Consultation" />
                    </ListItemButton>
                  </List>
                </Collapse>
              )}
            </React.Fragment>
          );
        })}
      </List>
    </Box>
  );
  return (
    <div
      className="sidenavbar fixed-top p-0"
      style={{ height: "100dvh", width: "15.5rem", display: "block" }}
      id="sidebar"
    >
      {list("left")}
      {/* <MenuIcon onClick={toggleDrawer("left", true)} /> */}
      {/* <Drawer
        anchor={"left"}
        open={state["left"]}
        onClose={toggleDrawer("left", false)}
        {/* </Drawer> */}
    </div>
  );
}

export default SideNavBar;
