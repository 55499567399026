import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import { AllImages } from "../assets/image";

export default function BookAppointment() {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm("service_6e1trwf", "template_0kastnc", form.current, {
        publicKey: "we0LtwIWTBhUi4VPS",
      })
      .then(
        () => {
          // console.log("SUCCESS!");
          alert("Appointment booked successfully!");
        },
        (error) => {
          // console.log("FAILED...", error.text);
          alert("Appointment failed!", error.text);
        }
      );
  };
  return (
    <div>
      <div className="container">
        <div className="row">
          <h2 className="text-center">Book Appointment</h2>
          <div className="col-md-6">
            <figure className="figure">
              <img
                src={AllImages.aboutusyoga}
                className="figure-img img-fluid rounded"
                alt="..."
              />
            </figure>
          </div>
          <div className="col-md-6">
            <form
              ref={form}
              onSubmit={sendEmail}
              className="form-control card flex-center dir-column p-2"
            >
              <input
                type="text"
                placeholder="Full Name"
                name="user_name"
                className="form-control my-2"
                required
              />
              <input
                type="email"
                placeholder="Email"
                name="user_email"
                className="form-control my-2"
                required
              />
              <input
                type="text"
                placeholder="Subjects"
                name="subject"
                className="form-control my-2"
                required
              />
              <input
                type="text"
                placeholder="Mobile"
                name="mobile"
                className="form-control my-2"
                required
              />
              <textarea
                className="form-control"
                name="message"
                cols="50"
                rows="10"
              ></textarea>
              <button
                type="submit"
                className=" form-control btn btn-primary my-2"
              >
                Send Message
              </button>
            </form>
          </div>
        </div>
      </div>
      {/* <form ref={form} onSubmit={sendEmail}>
        <label>Name</label>
        <input type="text" name="user_name" />
        <label>Email</label>
        <input type="email" name="user_email" />
        <label>Message</label>
        <textarea name="message" />
        <input type="submit" value="Send" />
      </form> */}
    </div>
  );
}
