import React from "react";
import { AllImages } from "../assets/image";

export default function Acupuncture() {
  return (
    <>
      <div
        className="container"
        style={{ backgroundColor: "#778899", color: "white" }}
      >
        <div className="row">
          <h1>Acupuncture Services in Durg</h1>
        </div>
      </div>
      <div className="container">
        <div className="row mt-4">
          <h1>About Acupuncture</h1>
        </div>
        <div className="row">
          <p>
            At Patanjali Wellness in Bowenpally, Hyderabad, our Ayurvedic and
            Naturopathy doctors offer a range of traditional therapies to
            promote wellness and balance in the body. One such therapy is
            Acupuncture, a natural and effective way to relieve pain and promote
            healing. Acupuncture is a therapeutic technique that involves the
            insertion of fine, sterile needles into specific points on the body
            known as acupoints. These points are believed to be connected to
            energy channels in the body, and stimulating them can help to
            balance the body’s energy, relieve pain, and promote healing.
          </p>
        </div>
        <div className="row">
          <div className="col-md-6">
            <img src={AllImages.Acupuncture1} width="500px" height="400px" />
            <img
              src={AllImages.Acupuncture2}
              width="500px"
              height="400px"
              className="mt-2"
            />
          </div>
          <div className="col-md-6">
            <h1>Benefits Of Acupuncture</h1>
            <p>Some of the benefits of Acupuncture include:</p>
            <ul class="list-group">
              <li class="list-group-item">
                Relief from pain and tension in the body, including headaches,
                back pain, and arthritis
              </li>
              <li class="list-group-item">Reduced stress and anxiety</li>
              <li class="list-group-item">
                Improved sleep quality and duration
              </li>
              <li class="list-group-item">
                Relief from digestive issues and menstrual problems
              </li>
              <li class="list-group-item">Improved immune system function</li>
              <li class="list-group-item">
                Enhanced mental clarity and emotional well-being
              </li>
              <li class="list-group-item">
                At Patanjali Wellness, our experienced practitioners use only
                sterile, disposable needles and follow strict hygiene and safety
                protocols to ensure that you receive the most effective and
                authentic experience.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
