import React from "react";
import { AllImages } from "../assets/image";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import FavoriteIcon from "@mui/icons-material/Favorite";
import NavigationIcon from "@mui/icons-material/Navigation";

export default function banner({ outletRef }) {
  const scrollpage = () => {
    if (outletRef.current) {
      const { current } = outletRef;
      window.scrollTo({
        top: current.offsetTop,
        behavior: "smooth",
      });
    }
  };
  // const myStyle = {
  //   color: "white",
  //   backgroundColor: "#868b51",
  // };
  const logostyle = {
    width: 100,
    height: 100,
  };

  return (
    <div>
      <div className="container-fluid">
        <div className="row align-items-center py-3">
          <div className="col col-lg-4">
            <img
              style={logostyle}
              src={AllImages.logowellness}
              style={{ height: 100 }}
              alt="logoin"
            />
            {/* <img style={logostyle} src={AllImages.AppLogo} alt="logoimage" /> */}
          </div>
          <div className="col col-lg-4">
            <img
              style={{ width: 400, height: 100 }}
              src={AllImages.CompanyName}
              alt="cmpnyname"
            />
          </div>
          <div className="col col-lg-4">
            <i className="fa-solid fa-phone me-1"></i>6399666401, 9589951141
            <br />
            <span className="p-2">
              {" "}
              <i className="fa-solid fa-envelope"></i>
            </span>
            mokshitniramayam@patanjaliwellness.com
          </div>
        </div>
        <div className=" py-0 d-flex">
          <Box sx={{ "& > :not(style)": { m: 1 } }} className="ms-auto">
            <Fab variant="extended" className="bg-danger text-white">
              <NavigationIcon sx={{ mr: 1 }} />
              <Link
                className="nav-link"
                to="/bookappointment"
                onClick={scrollpage}
                tabIndex="-1"
              >
                Book Appointment
              </Link>
            </Fab>
          </Box>
        </div>
      </div>
    </div>
  );
}
