import React from "react";
import { AllImages } from "../assets/image";

export default function Shirodhara() {
  return (
    <>
      <div
        className="container"
        style={{ backgroundColor: "#778899", color: "white" }}
      >
        <div className="row">
          <h1>Shirodhara Services in Durg</h1>
        </div>
      </div>
      <div className="container">
        <div className="row mt-4">
          <h1>About Shirodhara</h1>
        </div>
        <div className="row">
          <p>
            Shirodhara is a traditional Ayurvedic therapy that involves the
            continuous flow of warm oil over the forehead, specifically the
            “third eye” area. This therapy is to have a calming and rejuvenating
            effect on the mind and body, and is used to treat a variety of
            health conditions including stress, anxiety, insomnia, and
            headaches. Shirodhara is typically performed as part of a
            comprehensive Ayurvedic treatment plan, and may be combined with
            other therapies such as herbal remedies, massage, and yoga to
            achieve optimal results.
          </p>
        </div>
        <div className="row">
          <div className="col-md-6">
            <img src={AllImages.ShiroDhara1} width="500px" height="400px" />
            <img
              src={AllImages.Shirodhara2}
              width="500px"
              height="400px"
              className="mt-2"
            />
          </div>
          <div className="col-md-6">
            <h1>
              Indulge in the Ultimate Relaxation: The Shirodhara Procedure
            </h1>
            <p>Here’s a three-step process you can expect during your visit:</p>
            <ul class="list-group">
              <li class="list-group-item">
                <b>Relaxation:</b> Our highly trained therapist will start with
                a warm-up oil massage on your scalp, neck, and shoulder area to
                release any tension in your muscles. Then, warm oil will be
                poured continuously over your forehead in a steady stream for 30
                minutes, allowing you to reach a state of deep relaxation.
              </li>
              <li class="list-group-item">
                <b>Pouring of Oil:</b> Our therapist will use a special
                container called a “dhara pot” to pour the warm oil over your
                forehead in a steady stream. You’ll be encouraged to breathe
                deeply and let go of any stress or tension during this time.
              </li>
              <li class="list-group-item">
                <b>Completion:</b> At the end of the therapy, our therapist will
                wipe off the oil from your forehead and offer a final head and
                neck massage to complete the treatment. You’ll leave Patanjali
                Wellness feeling refreshed and rejuvenated.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
