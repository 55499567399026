import React from "react";
import { AllImages } from "../assets/image";

export default function ColonHydrotherapy() {
  return (
    <>
      <div
        className="container"
        style={{ backgroundColor: "#778899", color: "white" }}
      >
        <div className="row">
          <h1>Colon Hydrotherapy Services in Durg</h1>
        </div>
      </div>
      <div className="container">
        <div className="row mt-4">
          <h1>About Colon Hydrotherapy</h1>
        </div>
        <div className="row">
          <p>
            At Patanjali Wellness in Bowenpally, Hyderabad, our Ayurvedic and
            Naturopathy doctors offer a range of traditional therapies to
            promote wellness and balance in the body. One such therapy is Colon
            Hydrotherapy, a natural and effective way to cleanse and detoxify
            the colon or large intestine. Colon Hydrotherapy is a therapeutic
            treatment that involves the gentle cleansing and flushing out of the
            colon using clean, filtered water under gentle pressure. It is a
            safe and effective therapy that can help to remove accumulated
            waste, toxins, and harmful bacteria from the colon, promoting better
            digestion, absorption, and overall health.
          </p>
        </div>
        <div className="row">
          <div className="col-md-6">
            <img src={AllImages.ColonTherapy1} width="500px" height="400px" />
            <img
              src={AllImages.ColonTherapy2}
              width="500px"
              height="400px"
              className="mt-2"
            />
          </div>
          <div className="col-md-6">
            <h1>Benefits Of Colon Hydrotherapy</h1>
            <p>Some of the benefits of Colon Hydrotherapy include:</p>
            <ul class="list-group">
              <li class="list-group-item">
                Improved digestion and nutrient absorption
              </li>
              <li class="list-group-item">
                Relief from constipation, bloating, and other digestive issues
              </li>
              <li class="list-group-item">
                Relief from headaches, fatigue, and other symptoms of toxicity
              </li>
              <li class="list-group-item">Improved immune system function</li>
              <li class="list-group-item">
                Enhanced mental clarity and emotional well-being
              </li>
              <li class="list-group-item">
                Relief from skin conditions such as acne, eczema, and psoriasis
              </li>
              <li class="list-group-item">
                Improved weight management and metabolism
              </li>
              <li class="list-group-item">
                At Patanjali Wellness, our experienced practitioners use only
                the highest quality, filtered water in our Colon Hydrotherapy
                treatments to ensure that you receive the most effective and
                authentic experience.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
