import { useRef, useState } from "react";
import { Routes, Route } from "react-router-dom";
import "./App.css";
import Banner from "./components/banner.js";
// import Menu from "./components/menu.js";
import Contents from "./components/contents.js";
import Footer from "./components/footer.js";
import AboutUs from "./components/aboutus.js";
import ContactUs from "./components/contactus.js";
import Paymentqr from "./components/paymentqr.js";
import Services from "./components/services.js";
import EmailSend from "./components/Emailsend.js";
import SideNavBar from "./components/SideNavBar.js";
import Carousel from "./components/Carousel.js";
import Media from "./components/Media.js";
import Panchakarma from "./service/Panchakarma";
import Shatkarma from "./service/Shatkarma";
import Raktamokshana from "./service/Raktamokshana";
import Shirodhara from "./service/Shirodhara";
import Abhyangam from "./service/Abhyangam";
import PotliMassage from "./service/PotliMassage";
import DietTherapy from "./service/DietTherapy";
import MudPack from "./service/MudPack";
import ColonHydrotherapy from "./service/ColonHydrotherapy";
import Acupuncture from "./service/Acupuncture";
import CuppingTherapy from "./service/CuppingTherapy";
import Yoga from "./service/Yoga";
// import NewSideBar from "./components/NewSideBar.js";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";

function App() {
  const outletRef = useRef(null);

  const [isSideBarHidden, setIsSideBarHidden] = useState(false);

  // const style = {
  //   marginLeft: isSideBarHidden ? "" : `15.5rem`,
  // };

  return (
    <div className="App">
      <div className="container-fluid-lg">
        <div className="row">
          <SideNavBar outletRef={outletRef} />
          <div
            className="col-sm main-content"
            ref={(el) =>
              el &&
              el.style.setProperty(
                "margin-left",
                isSideBarHidden ? "0px" : "15.5rem",
                "important"
              )
            }
          >
            <button
              className="btn align-items-center mobile-icon"
              style={{
                padding: "1rem",
                width: "min-content",
                background: "none",
                border: "none",
                display: "flex",
              }}
              type="button"
              onClick={() => {
                const sideBarElement = document.getElementById("sidebar");
                const isHidden = sideBarElement.style?.display !== "block";

                if (isHidden) {
                  sideBarElement.style.display = "block";
                  setIsSideBarHidden(false);
                } else {
                  sideBarElement.style.display = "none";
                  setIsSideBarHidden(true);
                }
              }}
            >
              <MenuOpenIcon fontSize="large" color="success" />
            </button>

            <Banner outletRef={outletRef} />
            <Carousel />
            <div ref={outletRef}>
              <Routes>
                <Route path="/" element={<Contents />} />
                <Route path="/aboutus" element={<AboutUs />} />
                <Route path="/services" element={<Services />} />
                <Route path="/media" element={<Media />} />
                <Route path="/contactus" element={<ContactUs />} />
                <Route path="/paymentqr" element={<Paymentqr />} />
                <Route path="/bookappointment" element={<EmailSend />} />
                <Route path="/panchakarma" element={<Panchakarma />} />
                <Route path="/shatkarma" element={<Shatkarma />} />
                <Route path="/raktamokshana" element={<Raktamokshana />} />
                <Route path="/shirodhara" element={<Shirodhara />} />
                <Route path="/abhyangam" element={<Abhyangam />} />
                <Route path="/potliMassage" element={<PotliMassage />} />
                <Route path="/dietTherapy" element={<DietTherapy />} />
                <Route path="/mudPack" element={<MudPack />} />
                <Route
                  path="/colonHydrotherapy"
                  element={<ColonHydrotherapy />}
                />
                <Route path="/acupuncture" element={<Acupuncture />} />
                <Route path="/cuppingTherapy" element={<CuppingTherapy />} />
                <Route path="/yoga" element={<Yoga />} />
                {/* <Route path="/enquery" element={<Enquery />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/admissionForm" element={<AdmissionForm />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} /> */}
              </Routes>
            </div>
          </div>
        </div>
        <Footer isSideBarHidden={isSideBarHidden} />
      </div>
    </div>
  );
}

export default App;
