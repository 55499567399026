import React from "react";
import "../assets/css/content.css";
import { AllImages } from "../assets/image";
import MiddleContent from "../components/middlecontent.js";
const arr = [
  {
    title: "ASHTANGA YOGA",
    img: AllImages.astangyoga,
    color: "#f26d7d",
    style: {
      border: "5px solid #f26d7d",
      background: "rgba(242, 109, 125, 0.6)",
    },
  },
  {
    title: "HATHA YOGA",
    img: AllImages.hathayoga,
    color: "#f0bd26",
    style: {
      border: "5px solid #f0bd26",
      background: "rgba(240, 189, 38, 0.6)",
    },
  },
  {
    title: "IYENGAR YOGA",
    img: AllImages.iyengaryoga,
    color: "#9bd9da",
    style: {
      border: "5px solid #9bd9da",
      background: "rgba(155, 217, 218, 0.6)",
    },
  },
  {
    title: "KUNDALI YOGA",
    img: AllImages.kundaliyoga,
    color: "#e09866",
    style: {
      border: "5px solid #e09866",
      background: "rgba(224, 152, 102, 0.6)",
    },
  },
  {
    title: "POWER YOGA",
    img: AllImages.powerYoga,
    color: "#7e8446",
    style: {
      border: "5px solid #7e8446",
      background: "rgba(126, 132, 70,1)",
    },
  },
];

export default function contents() {
  return (
    <div>
      <div className="container-fluid mt-4">
        <div
          className="border-title text-center wow bounce animated"
          style={{ visibility: "visible" }}
        >
          <h5 id="ti" style={{ color: "#484338" }}>
            A BRIEF DESCRIPTION ABOUT THE WAYS OF YOGA
          </h5>
          <img src={AllImages.seprater2} />
          <br />
          <p style={{ color: "#484338", marginTop: 20 }}>
            We at MokshitNiramayam, combine various styles of yoga aligning
            physical postures, breathing techniques, and meditation or
            relaxation.It involves movement, meditation, and breathing
            techniques to promote mental and physical well-being.
          </p>
          <div style={{ margin: "0 4rem" }} className=" mt-5">
            <div className="row justify-content-between">
              {arr.map((item) => {
                return (
                  <div className="col-lg-2 grid-banner-type-5">
                    <div className="ovrly17">
                      <h5 style={{ color: item.color }}>{item.title}</h5>
                      <div className="icon-image" style={item.style}>
                        <img
                          src={item.img}
                          alt={item.title}
                          className="card-img-top rounded-circle"
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <MiddleContent />
    </div>
  );
}
