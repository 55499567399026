import React from "react";
import { AllImages } from "../assets/image";

export default function DietTherapy() {
  return (
    <>
      <div
        className="container"
        style={{ backgroundColor: "#778899", color: "white" }}
      >
        <div className="row">
          <h1>Diet Therapy Services in Durg</h1>
        </div>
      </div>
      <div className="container">
        <div className="row mt-4">
          <h1>About Diet Therapy</h1>
        </div>
        <div className="row">
          <p>
            At Patanjali Wellness in Bowenpally, Hyderabad, our Ayurvedic and
            Naturopathy doctors offer a range of traditional therapies to
            promote wellness and balance in the body. One such therapy is Diet
            Therapy, a personalized approach to nutrition that aims to improve
            your health and well-being through dietary changes. In Ayurveda and
            Naturopathy, food is considered a form of medicine. The food we eat
            affects our physical, mental, and emotional health. With Diet
            Therapy, our practitioners will work with you to create a
            personalized diet plan that is tailored to your individual needs and
            goals.
          </p>
        </div>
        <div className="row">
          <div className="col-md-6">
            <img src={AllImages.Diet} width="500px" height="400px" />
            <img
              src={AllImages.Diet1}
              width="500px"
              height="400px"
              className="mt-2"
            />
          </div>
          <div className="col-md-6">
            <h1>Benefits Of Diet Therapy</h1>

            <ul class="list-group">
              <li class="list-group-item">
                Improved digestion and absorption of nutrients
              </li>
              <li class="list-group-item">
                Reduced inflammation and oxidative stress in the body
              </li>
              <li class="list-group-item">
                Improved blood sugar and cholesterol levels
              </li>
              <li class="list-group-item">Increased energy and vitality</li>
              <li class="list-group-item">
                Improved mental clarity and cognitive function
              </li>
              <li class="list-group-item">Enhanced immune system function</li>
              <li class="list-group-item">
                Improved weight management and metabolism
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
