import React from "react";
import { AllImages } from "../assets/image";

export default function Abhyangam() {
  return (
    <>
      <div
        className="container"
        style={{ backgroundColor: "#778899", color: "white" }}
      >
        <div className="row">
          <h1>Abhyangam Services in Durg</h1>
        </div>
      </div>
      <div className="container">
        <div className="row mt-4">
          <h1>About Abhyangam</h1>
        </div>
        <div className="row">
          <p>
            Abhyangam is a traditional Ayurvedic therapy that involves a
            full-body massage with warm herbal oil. Originating in India over
            5,000 years ago, Abhyangam is designed to balance the three doshas
            (Vata, Pitta, and Kapha) and promote overall health and wellness.
            Abhyangam is an important part of the daily self-care routine in
            Ayurveda, as it is believed to help maintain good health and prevent
            the onset of disease. At Patanjali Wellness Centre in Hyderabad, our
            practitioners are trained in the traditional techniques of Abhyangam
            and are equipped to provide customized sessions tailored to meet the
            specific needs of each individual.
          </p>
        </div>
        <div className="row">
          <div className="col-md-6">
            <img src={AllImages.Abhyangam1} width="500px" height="400px" />
            <img
              src={AllImages.Abhyangam2}
              width="500px"
              height="400px"
              className="mt-2"
            />
          </div>
          <div className="col-md-6">
            <h1>
              Unwind and Rejuvenate: The Abhyangam Procedure for Total
              Relaxation
            </h1>

            <ul class="list-group">
              <li class="list-group-item">
                <b>Oil selection:</b> A suitable Patanjali oil is selected based
                on the individual’s body type, health condition, and specific
                needs.
              </li>
              <li class="list-group-item">
                <b>Massage:</b> The massage is performed using a gentle,
                rhythmic motion, with the oil applied to the entire body,
                including feet. The massage typically lasts for about 30-35
                minutes
              </li>
              <li class="list-group-item">
                <b>Steam bath: </b> After the massage, the patient is asked to
                take steam bath for the absorption of the oil and to cleanse the
                skin after the massage.
              </li>
              <li class="list-group-item">
                <b>Warm water bath:</b>After the steam bath, the patient is
                asked to take a warm water bath to remove any residual oil from
                the skin.
              </li>
              <li class="list-group-item">
                <b>Rest: </b>It is recommended to rest for a short period after
                the massage to allow the body to fully absorb the benefits of
                the treatment.
              </li>
            </ul>
            <p>
              It is important to note that the procedure may vary slightly based
              on the specific needs and preferences of each individual, and the
              guidance of a qualified Ayurvedic practitioner should always be
              followed.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
