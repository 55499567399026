import React from "react";
import { Allvideos } from "../assets/video/vidoIndex";

export default function Media() {
  return (
    <div>
      <h4>Media Project</h4>
      <video width="320" height="240" controls>
        <source src={Allvideos.video1} type="video/mp4" />
        <source src="movie.ogg" type="video/ogg" />
        Your browser does not support the video tag.
      </video>
      <video width="320" height="240" controls>
        <source src={Allvideos.video2} type="video/mp4" />
        <source src="movie.ogg" type="video/ogg" />
        Your browser does not support the video tag.
      </video>
      <video width="320" height="240" controls>
        <source src={Allvideos.video3} type="video/mp4" />
        <source src="movie.ogg" type="video/ogg" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
}
