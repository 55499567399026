import React from "react";
import { AllImages } from "../assets/image";

export default function CuppingTherapy() {
  return (
    <>
      <div
        className="container"
        style={{ backgroundColor: "#778899", color: "white" }}
      >
        <div className="row">
          <h1>Singhi/Cupping Therapy Services in Durg</h1>
        </div>
      </div>
      <div className="container">
        <div className="row mt-4">
          <h1>About Cupping Therapy</h1>
        </div>
        <div className="row">
          <p>
            At Patanjali Wellness in Bowenpally, Hyderabad, our Ayurvedic and
            Naturopathy doctors offer a range of traditional therapies to
            promote wellness and balance in the body. One such therapy is
            Singhi, also known as Cupping Therapy, a natural and effective way
            to relieve pain and promote healing. Singhi or Cupping Therapy is a
            therapeutic technique that involves the use of suction cups placed
            on specific areas of the body to stimulate blood flow, relieve
            tension, and promote healing. The cups are typically made of glass,
            plastic, or silicone and are placed on the skin using a pump or
            flame to create a vacuum effect.
          </p>
        </div>
        <div className="row">
          <div className="col-md-6">
            <img src={AllImages.CuppingTherapy} width="500px" height="400px" />
            {/* <img
              src={AllImages.Acupuncture2}
              width="500px"
              height="400px"
              className="mt-2"
            /> */}
          </div>
          <div className="col-md-6">
            <h1>Benefits Of Cupping Therapy</h1>
            <p>Some of the benefits of Cupping Therapy:</p>
            <ul class="list-group">
              <li class="list-group-item">
                Relief from pain and tension in the body, including back pain,
                neck pain, and joint pain
              </li>
              <li class="list-group-item">
                Improved blood circulation and lymphatic flow
              </li>
              <li class="list-group-item">Reduced inflammation and swelling</li>
              <li class="list-group-item">
                Relief from respiratory problems, including cough and asthma
              </li>
              <li class="list-group-item">Enhanced immune system function</li>
              <li class="list-group-item">Improved skin health</li>
              <li class="list-group-item">Relaxation and stress relief</li>
              <li class="list-group-item">
                At Patanjali Wellness, our experienced practitioners use a range
                of Singhi/Cupping Therapy techniques, including dry cupping, wet
                cupping, and fire cupping, to ensure that you receive the most
                effective and authentic experience.{" "}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
