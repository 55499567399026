import React from "react";
import { AllImages } from "../assets/image";

export default function middlecontent() {
  return (
    <div>
      <div className="container">
        <div className="grid-uniform my-4">
          <div
            className="cms-type-3-block"
            style={{
              backgroundImage: `url(${AllImages.parallaxdecor}
            )`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "bottom center",
              backgroundColor: "#f2e9d2",
            }}
          >
            <div className="container p-3">
              <div
                className="cms-type-3-content wow fadeInLeft animated text-right animated"
                style={{ visibility: "visible" }}
              >
                <div>
                  <h2
                    style={{
                      color: "#7e8846",
                      fontFamily: "PT Serif",
                      fontSize: "70px",
                      letterSpacing: "4px",
                    }}
                  >
                    Yoga, Ayurveda <br />
                    &amp; Naturopathy
                  </h2>
                  <p style={{ color: "#484338" }}>
                    MOKSHIT NIRAMAYAM brings to you an integrated and complete
                    health package.
                  </p>
                  <p style={{ color: "#484338", textAlign: "justify" }}>
                    Operating under the blessings of Revered Swami Ramdevji
                    Maharaj and the direction and guidance of Revered Acharya
                    Balkrishnaji, MOKSHIT NIRAMAYAM is an excellent and unique
                    concept for providing holistic treatment through an
                    integrated use of Yoga Mind-Body balance, Naturopathy(the
                    ancient Indian art of healing), Panchkarma therapies(Body
                    Detox), Acupressure and harmless herbs or medicinal plants.
                  </p>
                  <h5
                    style={{
                      color: "#484338",
                      textWrap: "wrap",
                    }}
                  >
                    Take a look at the amazing features &amp; ask yourself the
                    question?
                  </h5>
                  <div className="cms-type-3-btn">
                    <a
                      className="btn btn-2"
                      href="www.google.com"
                      style={{ backgroundColor: "#7e8846", color: "white" }}
                    >
                      View Packages
                    </a>
                    <a
                      className="btn mx-3"
                      href="www.google.com"
                      style={{ backgroundColor: "#ffda6b" }}
                    >
                      Know More
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <h2 style={{ color: "#484338" }}>Best Services For You</h2>
      <img src={AllImages.seprater2} alt="seperator2" alt="sep2" />
      <div className="container">
        <div className="container mt-4 text-center">
          <div className="row">
            <div className="col-md-6 mb-3">
              <div className="card">
                <img src={AllImages.aboutyogaf} alt="sep2" />
                <div className="dt-sc-hr-invisible-medium"></div>
                <div className="row">
                  <div className="col col-md-8 ms-1 my-5">
                    <p style={{ color: "#484338" }}>
                      We at MOKSHIT NIRAMAYAM provide various services to the
                      nature of the clients. Wish how you would like to spend
                      the time here we can talk &amp; come to a conclusion.
                    </p>
                  </div>
                  <div className="col col-md-3 my-5  ">
                    <a
                      className="btn"
                      style={{ backgroundColor: "#ffda6b" }}
                      href="#"
                    >
                      Learn More
                    </a>
                  </div>
                </div>
                <div className="progress-img-overlay"></div>
              </div>
            </div>
            <div className="col-sm-6 mb-3">
              {/* <div className="card"> */}
              {/* <div className="card-body"> */}
              <div className="row">
                <div className="col col-md-2 my-3">
                  <img
                    className="vibrate-1"
                    style={{ color: "#484338" }}
                    src={AllImages.ic1}
                    alt="So blinded by desire, that they cannot foresee the pain and trouble that are bound to ensue."
                  />
                </div>
                <div className="col col-md-4 my-3  ">
                  <h4 style={{ color: "#484338" }}>AYURVEDA</h4>
                </div>
                <hr style={{ backgroundColor: "#484338", height: 2 }} />
              </div>
              <div className="row">
                <div className="col col-md-2 my-3">
                  <img
                    className="vibrate-1"
                    src={AllImages.ic2}
                    alt="So blinded by desire, that they cannot foresee the pain and trouble that are bound to ensue; and equal blame belongs."
                  />
                </div>
                <div className="col col-md-10 my-3  ">
                  <h4 style={{ color: "#484338" }}>
                    RASAYANA(AYURVEDIC REJUVENATION THERAPY)
                  </h4>
                </div>
                <hr style={{ backgroundColor: "#484338", height: 2 }} />
              </div>
              <div className="row">
                <div className="col col-md-2 my-3">
                  <img
                    className="vibrate-1"
                    src={AllImages.ic3}
                    alt="So blinded by desire, that they cannot foresee the pain and trouble that are bound to ensue."
                  />
                </div>
                <div className="col col-md-5 my-3  ">
                  <h4 style={{ color: "#484338" }}>NATUROPATHY</h4>
                </div>
                <hr style={{ backgroundColor: "#484338", height: 2 }} />
              </div>
              <div className="row">
                <div className="col col-md-2 my-3">
                  <img
                    className="vibrate-1"
                    src={AllImages.ic4}
                    alt="So blinded by desire, that they cannot foresee the pain and trouble that are bound to ensue."
                  />
                </div>
                <div className="col col-md-5 my-3  ">
                  <h4 style={{ color: "#484338" }}>YOGA/MEDITATION</h4>
                </div>
                <hr style={{ backgroundColor: "#484338", height: 2 }} />
              </div>
              <div className="row">
                <div className="col col-md-2 my-3">
                  <img
                    className="vibrate-1"
                    src={AllImages.ic1}
                    alt="So blinded by desire, that they cannot foresee the pain and trouble that are bound to ensue."
                  />
                </div>
                <div className="col col-md-5 my-3  ">
                  <h4 style={{ color: "#484338" }}>PHYSIOTHERAPY</h4>
                </div>
                <hr style={{ backgroundColor: "#484338", height: 2 }} />
              </div>
              <div className="row">
                <div className="col col-md-2 my-3">
                  <img
                    className="vibrate-1"
                    src={AllImages.ic2}
                    alt="So blinded by desire, that they cannot foresee the pain and trouble that are bound to ensue; and equal blame belongs."
                  />
                </div>
                <div className="col col-md-5 my-3  ">
                  <h4 style={{ color: "#484338" }}>DOCTOR CONSULTATION</h4>
                </div>
              </div>
              {/* </div> */}
              {/* </div> */}
            </div>
          </div>
        </div>
        <div className="container pt-3">
          <h5 id="ti" style={{ color: "#484338" }}>
            Our Packages
          </h5>
          <img src={AllImages.seprater2} alt="sep2" />
          <br />
        </div>
        <div className="container mt-4 text-center">
          <div className="row">
            <div className="col-lg-4">
              <div className="card mx-auto" style={{ width: "18rem" }}>
                <img
                  src={AllImages.generalwellnesspackage}
                  alt="Meditation makes your mind relax"
                />
                <div className="card-body">
                  <p className="card-text">
                    <a
                      className="btn mx-3"
                      href="#"
                      style={{ backgroundColor: "#ffda6b" }}
                    >
                      General Wellness Package
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mt-2">
              <div className="card mx-auto" style={{ width: "18rem" }}>
                <img
                  src={AllImages.specialprogram}
                  alt="Meditation makes your mind relax"
                />
                <div className="card-body">
                  <p className="card-text">
                    <a
                      className="btn mx-3"
                      href="#"
                      style={{ backgroundColor: "#ffda6b" }}
                    >
                      Special Programmes
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mt-2">
              <div className="card mx-auto" style={{ width: "18rem" }}>
                <img
                  src={AllImages.generalprogram}
                  alt="Meditation makes your mind relax"
                />
                <div className="card-body">
                  <p className="card-text">
                    <a
                      className="btn mx-3"
                      href="#"
                      style={{ backgroundColor: "#ffda6b" }}
                    >
                      General Programmes
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container text-center my-4 ">
          <div className="grid__item">
            <h4 className="" style={{ color: "#484338" }}>
              Why MOKSHIT NIRAMAYAM?
            </h4>
            <img src={AllImages.seprater2} alt="sep2" />
          </div>
        </div>
        <div
          className="container text-center mt-4"
          style={{ border: "1px #D4AD76 solid" }}
        >
          <div className="row">
            <div className="col col-sm-4">
              <div className="row">
                {/* <div className="col col-md-2 my-3">
                  <img
                    className="vibrate-1"
                    style={{ color: "#484338" }}
                    src={AllImages.smallicon1}
                    alt="topic1"
                  />
                </div> */}
                <div className="col col-md-10 my-3  ">
                  <h6 style={{ color: "#484338" }}>
                    MOKSHIT NIRAMAYAM center is located in the Heart of the
                    city, easy to reach with ample parking space.
                  </h6>
                </div>
                {/* <span className="sepeartor"></span> */}
              </div>

              <div className="row">
                {/* <div className="col col-md-2 my-3">
                  <img
                    className="vibrate-1"
                    src={AllImages.smallicon1}
                    alt="topic2"
                  />
                </div> */}
                <div className="col col-md-10 my-3  ">
                  <h6 style={{ color: "#484338" }}>
                    Special Packages for various chronic diseases.
                  </h6>
                </div>
                {/* <span className="sepeartor"></span> */}
              </div>

              <div className="row">
                {/* <div className="col col-md-2 my-3">
                  <img
                    className="vibrate-1"
                    src={AllImages.smallicon1}
                    alt="topic3"
                  />
                </div> */}
                <div className="col col-md-8 my-3  ">
                  <h6 style={{ color: "#484338" }}>
                    In-house healthy food canteen available.
                  </h6>
                </div>
                {/* <span className="sepeartor"></span> */}
              </div>
            </div>
            <div className="col col-sm-4">
              <div className="row">
                {/* <div className="col col-md-2 my-3">
                  <img
                    className="vibrate-1"
                    style={{ color: "#484338" }}
                    src={AllImages.smallicon2}
                    alt="topic1"
                  />
                </div> */}
                <div className="col col-md-10 my-3  ">
                  <h6 style={{ color: "#484338" }}>
                    Chhattisgarh's first PATANJALI WELLNESS Center.
                  </h6>
                </div>
                {/* <span className="sepeartor"></span> */}
              </div>

              <div className="row">
                {/* <div className="col col-md-2 my-3">
                  <img
                    className="vibrate-1"
                    src={AllImages.smallicon2}
                    alt="topic2"
                  />
                </div> */}
                <div className="col col-md-10 my-3  ">
                  <h6 style={{ color: "#484338" }}>
                    Pick up and drop facility available.
                  </h6>
                </div>
                {/* <span className="sepeartor"></span> */}
              </div>

              <div className="row">
                {/* <div className="col col-md-2 my-3">
                  <img
                    className="vibrate-1"
                    src={AllImages.smallicon2}
                    alt="topic3"
                  />
                </div> */}
                <div className="col col-md-8 my-3  ">
                  <h6 style={{ color: "#484338" }}>Exclusive Yoga hall</h6>
                </div>
                {/* <span className="sepeartor"></span> */}
              </div>
            </div>
            <div className="col col-sm-4">
              <div className="row">
                {/* <div className="col col-md-2 my-3">
                  <img
                    className="vibrate-1"
                    style={{ color: "#484338" }}
                    src={AllImages.smallicon3}
                    alt="topic1"
                  />
                </div> */}
                <div className="col col-md-10 my-3  ">
                  <h6 style={{ color: "#484338" }}>
                    Chhattisgarh's first PATANJALI WELLNESS Center.
                  </h6>
                </div>
                {/* <span className="sepeartor"></span> */}
              </div>
              <div className="row">
                {/* <div className="col col-md-2 my-3">
                  <img
                    className="vibrate-1"
                    src={AllImages.smallicon3}
                    alt="topic3"
                  />
                </div> */}
                <div className="col col-md-8 my-3  ">
                  <h6 style={{ color: "#484338" }}>Exclusive Yoga hall</h6>
                </div>
                {/* <span className="sepeartor"></span> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
