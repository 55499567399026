import React from "react";
import QRCode from "react-qr-code";

export default function Paymentqr() {
  const locationLink = "https://www.example.com/location";
  const whatsappLink = "https://wa.me/yourphonenumber";
  const facebookLink = "https://www.facebook.com/yourprofile";
  const instagramLink = "https://www.instagram.com/yourprofile";

  return (
    <div>
      <h2>QR Code Generator</h2>
      <div>
        <h3>Location</h3>
        <QRCode value={locationLink} />
        <p>{locationLink}</p>
      </div>
      <div>
        <h3>WhatsApp</h3>
        <QRCode value={whatsappLink} />
        <p>{whatsappLink}</p>
      </div>
      <div>
        <h3>Facebook</h3>
        <QRCode value={facebookLink} />
        <p>{facebookLink}</p>
      </div>
      <div>
        <h3>Instagram</h3>
        <QRCode value={instagramLink} />
        <p>{instagramLink}</p>
      </div>
    </div>
  );
}
