import React from "react";
import { AllImages } from "../assets/image";

export default function Panchakarma() {
  return (
    <>
      <div
        className="container"
        style={{ backgroundColor: "#778899", color: "white" }}
      >
        <div className="row">
          <h1>Panchakarma Services in Durg</h1>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <h1>INTRODUCTION OF PANCHKARMA</h1>
        </div>
        <div className="row">
          <p>
            Panchakarma is a traditional Ayurvedic therapy designed to purify
            the body, mind, and spirit. This holistic approach to wellness uses
            a combination of dietary changes, herbal supplements, therapeutic
            treatments, and rejuvenation techniques to promote optimal health
            and well-being. The detoxification phase of Panchakarma involves
            methods such as vomiting, purgation, enema, nasal administration,
            and bloodletting, all aimed at removing accumulated toxins from the
            body. The rejuvenation phase uses herbal oils, massages, and other
            restorative treatments to promote physical and mental well-being.
            Whether you’re looking to improve your overall health, manage
            stress, or simply enhance your sense of well-being, Panchakarma is a
            powerful tool to help you achieve your goals..
          </p>
        </div>
        <div className="row">
          <div className="col-md-6">
            <img src={AllImages.panchakarma1} width="500px" height="400px" />
            <img
              src={AllImages.panchakarma2}
              width="500px"
              height="400px"
              className="mt-2"
            />
          </div>
          <div className="col-md-6">
            <h1>Discover the Different Forms of Panchakarma Therapy</h1>
            <p>
              Panchakarma is an Ayurvedic therapy that involves five primary
              methods of cleansing and rejuvenation. The five types of
              Panchakarma are:
            </p>

            <ul class="list-group">
              <li class="list-group-item">
                <b>Vamana:</b> therapeutic vomiting to remove excess Kapha from
                the body.
              </li>
              <li class="list-group-item">
                <b>Virechana:</b> purgation to eliminate excess Pitta from the
                body.
              </li>
              <li class="list-group-item">
                <b>Basti:</b> medicated enema to remove toxins from the colon
                and improve digestion.
              </li>
              <li class="list-group-item">
                <b>Nasya:</b> nasal administration of medicated oils to cleanse
                the sinuses and improve respiratory function.
              </li>
              <li class="list-group-item">
                <b>Raktamokshana:</b> bloodletting therapy to remove impurities
                from the bloodstream.
              </li>
            </ul>

            <p>
              It is important to note that Panchakarma should only be performed
              under the guidance of a qualified Ayurvedic practitioner to ensure
              safety and effectiveness. Each type of Panchakarma is tailored to
              the individual’s specific needs and health conditions, making it a
              highly personalized and effective approach to wellness.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
