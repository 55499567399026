import React from "react";
import { AllImages } from "../assets/image";

export default function Shatkarma() {
  return (
    <>
      <div
        className="container"
        style={{ backgroundColor: "#778899", color: "white" }}
      >
        <div className="row">
          <h1>Shatkarma Services in Durg</h1>
        </div>
      </div>
      <div className="container">
        <div className="row mt-4">
          <h1>About Shatkarma</h1>
        </div>
        <div className="row">
          <p>
            Shatkarma is a set of six purification techniques in Ayurveda that
            promote physical and mental well-being. These techniques include
            Jalaneti (nasal cleansing), Kapalbhati (frontal lobe cleansing),
            Trataka (gazing), Nauli (abdominal massage), Dhauti (cleansing of
            the digestive tract), and Basti (colon cleansing). At Patanjali
            Wellness Centre in Hyderabad, our experienced practitioners will
            guide you through each of these techniques, helping you to achieve
            optimal health and wellness. Whether you’re looking to improve your
            digestion, enhance your mental clarity, or simply promote a sense of
            overall well-being, Shatkarma is an effective and holistic approach
            to your health and happiness. Book your appointment today and
            experience the transformative power of Shatkarma at Patanjali
            Wellness Centre in Hyderabad.
          </p>
        </div>
        <div className="row">
          <div className="col-md-6">
            <img src={AllImages.shatkarma4} width="500px" height="400px" />
            <img
              src={AllImages.shatkarma2}
              width="500px"
              height="400px"
              className="mt-2"
            />
          </div>
          <div className="col-md-6">
            <h1>Benefits Of Shatkarma</h1>
            <p>
              The benefits of Shatkarma, the set of six purification techniques
              in Ayurveda, include:
            </p>

            <ul class="list-group">
              <li class="list-group-item">
                Improved digestion and elimination: The cleansing techniques of
                Shatkarma help to remove toxins from the digestive tract and
                improve the functioning of the digestive system.
              </li>
              <li class="list-group-item">
                Increased mental clarity and focus: By cleansing the frontal
                lobe and improving nasal respiration, Shatkarma can help to
                enhance mental clarity and focus.
              </li>
              <li class="list-group-item">
                Enhanced physical strength and flexibility: Through techniques
                such as Nauli, Shatkarma helps to improve core strength and
                flexibility.
              </li>
              <li class="list-group-item">
                Reduced stress and anxiety: The deep breathing and meditative
                techniques involved in Shatkarma can help to reduce stress and
                anxiety.
              </li>
              <li class="list-group-item">
                Boosted immunity: By removing toxins and promoting optimal
                functioning of the digestive system, Shatkarma can help to boost
                the immune system.
              </li>
              <li class="list-group-item">
                Improved overall health and well-being: The holistic approach of
                Shatkarma, which focuses on purifying the body, mind, and
                spirit, can lead to improved overall health and well-being.
              </li>
            </ul>
            <p>
              It is important to note that the benefits of Shatkarma can vary
              depending on the individual and should only be performed under the
              guidance of a qualified Ayurvedic practitioner.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
