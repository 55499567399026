export const AllImages = {
  AppLogo: require("./mokshitlogo.png"),
  CompanyName: require("./logomok.png"),
  slider1: require("./slider1.jpg"),
  slider2: require("./slider2.jpg"),
  slider4: require("./slider4.jpg"),
  powerYoga: require("./poweryoga.jpg"),
  astangyoga: require("./astangyoga.jpg"),
  hathayoga: require("./hathayoga.jpg"),
  iyengaryoga: require("./iyengaricon.jpg"),
  kundaliyoga: require("./kundaliyoga.jpg"),
  mokshitlogo: require("./mokshitlogo.png"),
  mokshit: require("./mokshit.png"),
  logowellness: require("./logowellness.png"),
  paymenticon1: require("./paymenticon1.png"),
  paymenticon2: require("./paymenticon2.png"),
  paymenticon3: require("./paymenticon3.png"),
  paymenticon4: require("./paymenticon4.png"),
  aboutyogaf: require("./aboutyogaf.png"),
  ic1: require("./ic1.png"),
  ic2: require("./ic2.png"),
  ic3: require("./ic3.png"),
  ic4: require("./ic4.png"),
  parallaxdecor: require("./parallaxdecor.png"),
  generalwellnesspackage: require("./generalwellnesspackage.jpg"),
  specialprogram: require("./specialprogram.jpg"),
  generalprogram: require("./generalprogram.jpg"),
  smallicon1: require("./smallicon1.png"),
  smallicon2: require("./smallicon2.png"),
  smallicon3: require("./smallicon3.png"),
  seprater2: require("./divider227c.png"),
  aboutusyoga: require("./about-us.jpg"),
  swamiji: require("./swamiji.jpg"),
  acharyaji: require("./acharyaji.jpg"),
  panchakarma1: require("./Panchakarma (2).jpg"),
  panchakarma2: require("./Panchakarma (3).jpg"),
  shatkarma4: require("./Shatkarma (4).jpg"),
  shatkarma2: require("./Shatkarma (2).jpg"),
  Raktmokshan1: require("./Raktmokshan.jpeg"),
  Raktmokshan2: require("./Raktmokshan 1.jpeg"),
  ShiroDhara1: require("./ShiroDhara5.jpg"),
  Shirodhara2: require("./Shirodhara1.jpg"),
  Abhyangam1: require("./Abhyangam2.jpg"),
  Abhyangam2: require("./Abhyangam3.jpg"),
  PotliMessage1: require("./PotliMessage1.jpg"),
  PotliMessage2: require("./PotliMessage2.jpg"),
  Diet: require("./Diet.jpeg"),
  Diet1: require("./Diet1.jpeg"),
  Mudpack1: require("./Mudpack3.jpg"),
  Mudpack2: require("./Mudpack2.jpg"),
  ColonTherapy1: require("./ColonTherapy1.jpg"),
  ColonTherapy2: require("./ColonTherapy2.jpeg"),
  Acupuncture1: require("./Acupuncture.jpeg"),
  Acupuncture2: require("./Acupuncture1.jpeg"),
  CuppingTherapy: require("./CuppingTherapy.jpeg"),
  Yoga: require("./Yoga.jpeg"),
};
