import React from "react";
import { AllImages } from "../assets/image";

export default function footer({ isSideBarHidden }) {
  return (
    <div
      style={{
        marginLeft: isSideBarHidden ? "" : "15.5rem",
      }}
    >
      <div className="row mt-4" style={{ background: "#d4ad76" }}>
        <div className="col-md-4 mt-4">
          <h4 style={{ color: "#484338" }}>WORK HOURS</h4>
          <span className="sepeartor"></span>
          <br />
          <p>
            Working Hours <span>8:00 AM - 6:00 PM</span>
          </p>
          <p>
            Consultancy Timing <span>9:00 AM - 6:00 PM</span>
          </p>
        </div>
        <div className="col-md-4 mt-4">
          <div className="footer-logo">
            <a href="https://mokshitniramayam.com/">
              <img
                className="normal-footer-logo"
                src={AllImages.mokshit}
                alt="Kriya"
                style={{ width: 200, height: 200 }}
              />
            </a>
            <a href="https://mokshitniramayam.com/">
              <img
                className="normal-footer-logo"
                src={AllImages.logowellness}
                alt="Kriya"
              />
            </a>
            <p style={{ color: "#484338" }}>
              Mokshit Niramayam brings to you an integrated and complete health
              package.
            </p>
            <div className="footer_social_icons">
              <a
                className="icon-fallback-text twitt hexagon"
                target="blank"
                href="#"
                title="Twitter"
                style={{
                  width: 200,
                  height: 200,
                  marginLeft: 5,
                  textDecoration: "none",
                }}
              >
                <span className="fab fa-twitter" aria-hidden="true"></span>
                <span className="fallback-text">Twitter</span>
              </a>

              <a
                className="icon-fallback-text fb hexagon"
                target="blank"
                href="#"
                title="Facebook"
                style={{
                  width: 200,
                  height: 200,
                  marginLeft: 5,
                  textDecoration: "none",
                }}
              >
                <span className="fab fa-facebook" aria-hidden="true"></span>
                <span className="fallback-text">Facebook</span>
              </a>

              <a
                className="icon-fallback-text google hexagon"
                target="blank"
                href="#"
                title="Google+"
                rel="publisher"
                style={{
                  width: 200,
                  height: 200,
                  marginLeft: 5,
                  textDecoration: "none",
                }}
              >
                <span
                  className="fab fa-google-plus-g"
                  aria-hidden="true"
                ></span>
                <span className="fallback-text">Google</span>
              </a>

              <a
                className="icon-fallback-text tumblr"
                target="blank"
                href="#"
                title="Tumblr"
                style={{
                  width: 200,
                  height: 200,
                  marginLeft: 5,
                  textDecoration: "none",
                }}
              >
                <span className="fab fa-tumblr" aria-hidden="true"></span>
                <span className="fallback-text">Tumblr</span>
              </a>
            </div>
          </div>
        </div>
        <div className="col-md-4 mt-4">
          <div className="footer_contact_info">
            <h4 style={{ color: "#484338" }}>CONTACT US</h4>
            <span className="sepeartor"></span>
            <br />
            <ul style={{ listStyle: "none" }}>
              <li className="address">
                {" "}
                <h6 style={{ color: "#484338" }}>
                  <span className="fa fa-home"></span>
                </h6>{" "}
                <p style={{ color: "#484338" }}>G.E. Road, Ganjpara, Durg</p>
                <span className="sepeartor"></span>
                <br />
              </li>
              <li>
                <h6 style={{ color: "#484338" }}>
                  <span className="fa fa-envelope"></span>
                </h6>
                <p>
                  {" "}
                  <a
                    style={{ color: "#484338" }}
                    title=""
                    href="mailto: mokshitniramayam@patanjaliwellness.com"
                  >
                    {" "}
                    mokshitniramayam@patanjaliwellness.com
                  </a>{" "}
                </p>
              </li>
              <li>
                {" "}
                <h6 style={{ color: "#484338" }}>
                  <span className="fa fa-mobile"></span>
                </h6>
                <p style={{ color: "#484338" }}>
                  <b>7883500331, 6399666401</b>
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row" style={{ background: "#54452f", height: 200 }}>
        <div className="col col-md-12 mt-4">
          <ul className="list-inline">
            <li className="list-inline-item">
              <a
                style={{ color: "#a58251", textDecoration: "none" }}
                href="https://mokshitniramayam.com/"
              >
                Home |
              </a>
            </li>
            <li className="list-inline-item">
              <a style={{ color: "#a58251", textDecoration: "none" }} href="#">
                About Us |
              </a>
            </li>
            <li className="list-inline-item">
              <a style={{ color: "#a58251", textDecoration: "none" }} href="#">
                Services |
              </a>
            </li>
            <li className="list-inline-item">
              <a style={{ color: "#a58251", textDecoration: "none" }} href="#">
                Privacy Policy |
              </a>
            </li>
            <li className="list-inline-item">
              <a style={{ color: "#a58251", textDecoration: "none" }} href="#">
                Contact Us
              </a>
            </li>
          </ul>
        </div>
        <div className="col col-md-6 ">
          <span></span>
          <p style={{ color: "#a58251" }} className="copyright">
            Copyright © 2022, Mokshit Niramayam
            <a
              target="_blank"
              rel="nofollow"
              href="#"
              style={{ color: "#a58251", textDecoration: "none" }}
            >
              <span>&nbsp;</span>Powered by UpasyaFoundation Group
            </a>
          </p>
        </div>
        <div className="col col-md-6">
          <ul className="list-inline payment-icons">
            <li className="list-inline-item">
              <a href="#" style={{ textDecoration: "none" }}>
                <img src={AllImages.paymenticon1} alt="payment_icon_1" />
              </a>
            </li>
            <li className="list-inline-item">
              <a href="#" style={{ textDecoration: "none" }}>
                <img src={AllImages.paymenticon2} alt="payment_icon_2" />
              </a>
            </li>
            <li className="list-inline-item">
              <a href="#" style={{ textDecoration: "none" }}>
                <img src={AllImages.paymenticon3} alt="payment_icon_3" />
              </a>
            </li>
            <li className="list-inline-item">
              <a href="#" style={{ textDecoration: "none" }}>
                <img src={AllImages.paymenticon4} alt="payment_icon_4" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
