import React from "react";
import { AllImages } from "../assets/image";

export default function Yoga() {
  return (
    <>
      <div
        className="container"
        style={{ backgroundColor: "#778899", color: "white" }}
      >
        <div className="row">
          <h1>Yoga Services in Durg</h1>
        </div>
      </div>
      <div className="container">
        <div className="row mt-4">
          <h1>About Yoga</h1>
        </div>
        <div className="row">
          <p>
            At Patanjali Wellness in Bowenpally, Hyderabad, we are committed to
            promoting holistic health and wellness for all. That’s why we offer
            a free Yoga service to all, regardless of age or fitness level. Yoga
            is an ancient practice that combines physical postures, breathing
            techniques, and meditation to promote relaxation, strength,
            flexibility, and overall well-being. Our Yoga sessions at Patanjali
            Wellness are led by experienced teachers who will guide you through
            a series of postures and breathing exercises designed to meet your
            individual needs and goals.
          </p>
        </div>
        <div className="row">
          <div className="col-md-6">
            <img src={AllImages.Yoga} width="500px" height="400px" />
            {/* <img
              src={AllImages.Acupuncture2}
              width="500px"
              height="400px"
              className="mt-2"
            /> */}
          </div>
          <div className="col-md-6">
            <h1>Benefits Of Yajya Therapy Include:</h1>
            <p>Some of the benefits of practicing Yoga include:</p>
            <ul class="list-group">
              <li class="list-group-item">
                Improved flexibility, balance, and strength
              </li>
              <li class="list-group-item">Reduced stress and anxiety</li>
              <li class="list-group-item">
                Improved sleep quality and duration
              </li>
              <li class="list-group-item">
                Relief from chronic pain and tension
              </li>
              <li class="list-group-item">
                Enhanced mental clarity and emotional well-being
              </li>
              <li class="list-group-item">Improved immune system function</li>
              <li class="list-group-item">
                At Patanjali Wellness, we believe that Yoga is a powerful tool
                for promoting health and wellness, and we are committed to
                making it accessible to all.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
