import React from "react";
import { AllImages } from "../assets/image";

export default function PotliMassage() {
  return (
    <>
      <div
        className="container"
        style={{ backgroundColor: "#778899", color: "white" }}
      >
        <div className="row">
          <h1>Potli Massage Services in Durg</h1>
        </div>
      </div>
      <div className="container">
        <div className="row mt-4">
          <h1>About Potli Massage</h1>
        </div>
        <div className="row">
          <p>
            Potli massages utilize a traditional approach rooted in the
            harmonization of the elements of fire and water, along with a blend
            of specific herbs. By applying heated potlis to the body, the pores
            open up, relaxing the muscles and allowing the herbs to effectively
            soothe both the body and mind. This method is well-known for its
            ability to promote relaxation.
          </p>
        </div>
        <div className="row">
          <div className="col-md-6">
            <img src={AllImages.PotliMessage1} width="500px" height="400px" />
            <img
              src={AllImages.PotliMessage2}
              width="500px"
              height="400px"
              className="mt-2"
            />
          </div>
          <div className="col-md-6">
            <h1>
              The Magic of Potli Massage: 10 Ways it Can Transform Your Body and
              Mind"
            </h1>

            <ul class="list-group">
              <li class="list-group-item">
                Relieves pain and stiffness in joints and muscles
              </li>
              <li class="list-group-item">
                Improves blood circulation and oxygen supply to tissues
              </li>
              <li class="list-group-item">Enhances skin health and texture</li>
              <li class="list-group-item">
                Boosts immunity and body’s natural healing process
              </li>
              <li class="list-group-item">
                Promotes relaxation and reduces stress and anxiety
              </li>
              <li class="list-group-item">
                Stimulates lymphatic drainage and detoxification
              </li>
              <li class="list-group-item">
                Balances the doshas and promotes overall wellness
              </li>
              <li class="list-group-item">
                Improves digestion and relieves constipation
              </li>
              <li class="list-group-item">
                Provides relief from respiratory ailments such as asthma and
                sinusitis
              </li>
              <li class="list-group-item">
                Helps in reducing the appearance of cellulite and improves body
                contour.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
