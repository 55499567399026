import React from "react";
import { AllImages } from "../assets/image";

export default function MudPack() {
  return (
    <>
      <div
        className="container"
        style={{ backgroundColor: "#778899", color: "white" }}
      >
        <div className="row">
          <h1>Mud Pack Services in Durg</h1>
        </div>
      </div>
      <div className="container">
        <div className="row mt-4">
          <h1>About Mud Pack</h1>
        </div>
        <div className="row">
          <p>
            At Patanjali Wellness in Bowenpally, Hyderabad, our Ayurvedic and
            Naturopathy doctors offer a range of traditional Ayurvedic therapies
            to promote wellness and balance in the body. One such therapy is Mud
            Pack, a natural and effective way to detoxify the body and promote
            overall health. Mud Pack is a traditional Ayurvedic therapy that
            involves applying a thin layer of mud to the body, typically the
            abdomen, face, and head. The mud used in the therapy is prepared by
            keeping soaked mud in a thin, wet muslin cloth and making it into a
            thin flat brick depending on the size of the patient’s abdomen,
            face, and head.
          </p>
        </div>
        <div className="row">
          <div className="col-md-6">
            <img src={AllImages.Mudpack1} width="500px" height="400px" />
            <img
              src={AllImages.Mudpack2}
              width="500px"
              height="400px"
              className="mt-2"
            />
          </div>
          <div className="col-md-6">
            <h1>Benefits Of Mud Pack</h1>
            <p>
              Mud pack application areas, abdomen, face [eyes, fore head]
              Duration of mud pack; 20-30 mins
            </p>
            <ul class="list-group">
              <li class="list-group-item">
                Detoxification of the body, removing toxins and impurities
              </li>
              <li class="list-group-item">
                Rejuvenation of the skin and tissues
              </li>
              <li class="list-group-item">Relief from pain and inflammation</li>
              <li class="list-group-item">
                Relaxation and reduction of stress and anxiety
              </li>
              <li class="list-group-item">
                Improved blood circulation and lymphatic flow
              </li>
              <li class="list-group-item">
                Relief from skin conditions such as acne, eczema, and psoriasis
              </li>
              <li class="list-group-item">
                Improved immunity and overall health
              </li>
              <li class="list-group-item">
                At Patanjali Wellness, our experienced practitioners use only
                the highest quality, organic mud in our Mud Pack treatments to
                ensure that you receive the most effective and authentic
                experience.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
