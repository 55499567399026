import React from "react";
import { AllImages } from "../assets/image";

export default function aboutus() {
  return (
    <div>
      <div className="container" id="aboutpage">
        <div className="row mt-4">
          <h3>About Us</h3>
          <div className="col col-6">
            <img
              src={AllImages.aboutusyoga}
              alt="about"
              className="img-thumbnail"
            />
          </div>
          <div className="col col-6">
            <h4>Mokshit Niramayam</h4>
            <p>
              समदोषः समाग्निश्च समधातु मलक्रियाः। प्रसन्नात्मेन्द्रियमनाः स्वस्थ
              इत्यभिधीयते।। Mokshit Niramayam brings to you an integrated and
              complete health package. A fusion of, Naturopathy (the ancient
              Indian art of healing), Nature cure (drugless cure), Yoga (mind
              body balance), Panchkarma therapies (Body Detox) and multitude and
              relaxing therapies. The therapies are provided under the guidance
              of our experienced doctor and therapist in a hygienic, eco
              friendly and luxurious environment.
            </p>
          </div>
        </div>
        <div className="row mt-4">
          <h4>Objectives</h4>
          <p>
            Providing physical, mental and spiritual health, happiness, peace
            and prosperity to the whole afflicted mankind suffering from
            incurable diseases, through the integrated therapy of Ayurveda
            (āuh+veda), the science of ensuring longevity and health, Yoga and
            Naturopathy, Patanjali Wellness presents a unique combination of the
            ancient traditional medical system and the modern diagnostics.
          </p>
        </div>
        <div className="row mt-4">
          <h4>Our Leadership</h4>
          <div className="col col-md-6">
            <p>
              Param Pujya Yogrishi Swami Ramdev Ji was born to Smt. Gulab Devi
              and Shri Ram Niwas in a village of Haryana. He had his early
              education in a village school. At the age of fourteen, he was
              admitted to the Gurukul at Kalwa (near Jind, Haryana), where under
              the blessed tutelage of Acharya Shri Baldevji, he studied Sanskrit
              and Yoga, and earned a postgraduate (Acharya) degree with a
              specialization in Sanskrit Vyakaraṇa, Yoga, Darsana, Vedas, and
              Upaniṣads later he was very much inspired by the life and writings
              of Maharṣi Dayanand Saraswati and he thoroughly studied Satyartha
              Prakasa, RG Vedadi Bhasya Bhumika, etc. Alongside the magnetism of
              Maharsi. Pantanjali as an exponent of Yoga, Sanskrit Grammar and
              Ayurveda continued to exert its influence on him. He has explained
              in detail the benefits accruing from yoga in his two popular Hindi
              books on the subject: Yoga Sadhana evam Yoga Chikitsa Rahasya
              Praṇayama Rahasya. Praṇayama Rahasya
            </p>
          </div>
          <div className="col col-md-6">
            <img
              src={AllImages.swamiji}
              alt="swamiji"
              className="img-thumbnail"
            />
          </div>
        </div>
        <div className="row mt-4">
          <div className="col col-md-6">
            <img
              src={AllImages.acharyaji}
              alt="acharyaji"
              className="img-thumbnail"
            />
          </div>
          <div className="col col-md-6">
            <h4>Ayurved Shiromani Acharya Balkrishna Ji</h4>
            <p>
              Ayurved Shiromani Acharya Balkrishna ji, a multi-faceted
              personality, is a famous authority on Ayurveda and a renowned
              medicinal plant expert. He is a companion of Swami Ramdev Ji, who
              is known for the Yoga Revolution in the World and a founding
              pillar of Patanjali Yogpeeth and Divya Yoga Mandir (Trust). He is
              a man who has uplifted the spiritual tradition of ancient saints.
              Ayurved Shiromani Acharya Balkrishna ji is a renowned scholar and
              a great guide under whose guidance and leadership Ayurvedic
              treatment and research has touched new dimensions. Acharya Ji has
              got worldwide regards as an empowerer of Ayurvedic system of
              medicine. His efforts have significantly contributed to the
              social, cultural, educational, and economic aspects and the
              establishment of excellent standards in the domains of literature
              and therapy at a global level.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
